import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import "@/stules/font/iconfont.css"
import 'jquery/dist/jquery.min'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/js/bootstrap.min.js'

import $ from 'jquery'
Vue.prototype.$ = $

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
